
















































import BaseCard from "../../Base/BaseCard.vue";
import BaseWrapper from "../../Base/BaseWrapper.vue";
import { Vue, Component } from "vue-property-decorator";
import { getPermission } from "@/services/Push";

@Component({
    components: { BaseWrapper, BaseCard },
})
export default class SharedDashboardGetNotification extends Vue {
    public isLoading: boolean = false;

    get currentToken() {
        return this.$store.state.users.currentToken;
    }

    get me() {
        return this.$store.state.users.me;
    }

    get showNotification() {
        let show = false;

        if (!(this.me && this.me.fcm_token)) return show;
        show = this.me.fcm_token !== this.currentToken;

        return show;
    }

    async getPermission() {
        this.isLoading = true;
        await getPermission();
        this.isLoading = false;
    }
}
