const Push = require("push.js");
const PushFCM = require("push-fcm-plugin");

import store from "@/store";

Push.extend(PushFCM);

var config = {
    apiKey: "AIzaSyAklVIC58eP8QDv9FoNHHmorsePZfhtSck",
    authDomain: "ecsr-dinsos.firebaseapp.com",
    projectId: "ecsr-dinsos",
    databaseURL: "ecsr-dinsos.firebaseio.com",
    storageBucket: "ecsr-dinsos.appspot.com",
    messagingSenderId: "1018783673272",
    appId: "1:1018783673272:web:4adf426597ee7661bbfd4d",
};

Push.config({
    FCM: config,
});

export const getPermission = async () => {
    const onGranted = async () => {
        Push.FCM()
            .then(function(FCM: any) {
                FCM.getToken()
                    .then(function(token: any) {
                        storeToken(token);
                    })
                    .catch(function(tokenError: any) {
                        throw tokenError;
                    });
            })
            .catch(function(initError: any) {
                throw initError;
            });
    };

    const onDenied = () => {
        console.log("denied");
    };

    const waitFor = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

    const storeToken = async (token: string | null) => {
        await waitFor(1000);

        const me = (store.state as any).users.me;
        const isLoggedIn = !!me;

        if (token && isLoggedIn) {
            // console.log('Storing token:', token);
            
            
            await store.dispatch("users/setFcmToken", token).then(() => {
                // console.log('Token stored.');

                // var pushNotification = Push.create("Berhasil menerima notifikasi!", {
                //     tag: "foo",
                //     body: "Token anda adalah "+ token,
                //     icon: "/icon.png",
                //     timeout: 4000,
                //     onClick: function() {
                //         window.focus();
                //         this.close();
                //     },
                // });
                
            });
        }
    }; 

    await Push.Permission.request(onGranted, onDenied);
};
